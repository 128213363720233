import {Component, OnInit} from '@angular/core';
// import {GoogleTagManagerService} from "angular-google-tag-manager";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['../../../assets/style/app.component.scss']
})


export class AppComponent implements OnInit {
  title = 'babaLandingPages';


  constructor() {

  }

  ngOnInit() {
    // this.gtmService.addGtmToDom();
  }

  onActivate(event: any) {
    window.scrollTo(0, 0);
  }
}
