<div id="contactUsPage" class="container-fluid">
  <div class="container">


    <div class="row">
<!--      <div class="col">-->
<!--        <img [src]="mainLogoSrc" alt="" class="main-logo mx-auto d-block">-->
<!--      </div>-->
    </div>
      <div style="margin-top: 130px">

    <app-contact-us-form [userObj]="queryParam"></app-contact-us-form>
          </div>


  </div>
</div>

