import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss']
})
export class ContactUsPageComponent implements OnInit {
  queryParam: string = '';
  mainLogoSrc: string;


  constructor(private route: ActivatedRoute) {
    this.mainLogoSrc = '../../../assets/images/logos/babacasinologo.png'
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.queryParam = params['testParam1'];
    });
  }

}
