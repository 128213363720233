<div id="{{modalId}}">

  <div class="close-btn btn-actions" (click)="closeModal()">x</div>

  <header class="modal-header row">
    <div class="col">
      <h3>Apply for <span>{{careerObj.title}}</span> Position</h3>
    </div>
  </header>

  <section class="modal-body form-wrapper row">
    <form class="col" [formGroup]="applyJobForm" autocomplete="on" (ngSubmit)="onSubmit()">

      <div class="row">
        <div class="form-group col-md-6">
          <label for="firstName">First Name</label>
          <input id="firstName" type="text" formControlName="firstName" class="form-control"
                 [ngClass]="{'is-invalid': applyJobForm.get('firstName').errors && submitted}"/>
          <control-validate-error class="control-validate-error" [control]="applyJobForm.get('firstName')"
                                  [isSubmitted]="submitted"></control-validate-error>
        </div>
        <div class="form-group col-md-6">
          <label for="lastName">Last Name</label>
          <input id="lastName" type="text" formControlName="lastName" class="form-control"
                 [ngClass]="{ 'is-invalid': applyJobForm.get('lastName').errors && submitted}"/>
          <control-validate-error class="control-validate-error" [control]="applyJobForm.get('lastName')"
                                  [isSubmitted]="submitted"></control-validate-error>
        </div>
      </div>
      <!--first and last-->

      <div class="row mb-2">
        <div class="col-md-6">
          <label for="email">Email</label>
          <input id="email" type="text" formControlName="email" class="form-control"
                 [ngClass]="{ 'is-invalid': applyJobForm.get('email').errors && submitted}"/>

          <control-validate-error class="control-validate-error" [control]="applyJobForm.get('email')"
                                  [isSubmitted]="submitted"></control-validate-error>


        </div>
        <div class="col-md-6">
          <label for="phone">Phone Number</label>
          <input id="phone" type="text" formControlName="phone" class="form-control"
                 [ngClass]="{ 'is-invalid': applyJobForm.get('phone').errors && submitted}"/>

          <control-validate-error class="control-validate-error" [control]="applyJobForm.get('phone')"
                                  [isSubmitted]="submitted"></control-validate-error>


        </div>
      </div>
      <!--Email and phone number-->

      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="message">Additional text</label>
            <textarea id="message" formControlName="message" class="form-control" rows="4" cols="50"
                      placeholder="Write your message"
                      [ngClass]="{ 'is-invalid': applyJobForm.get('message').errors && submitted}"></textarea>
            <control-validate-error class="control-validate-error" [control]="applyJobForm.get('message')"
                                    [isSubmitted]="submitted"></control-validate-error>
          </div>
        </div>
      </div>
      <!--Message-->


      <div class="row inner-title">
        <div class="col">
          <h6>Resume</h6>
          <div *ngIf="!resumeSectionIsValid && submitted" class="invalid-feedback d-block text-center">
            One of the fields below are required
          </div>
        </div>
      </div>

      <div class="row mb-3">Ï
        <div class="choose-file-row col-md-4">
          <label class="btn btn-primary btn-block pl-0 pr-0 text-white" for="fileUpload">Upload File <i
            class="fa fa-upload"></i></label>
          <input id="fileUpload" class="choose-file-row" type="file" accept=".pdf, .doc, .docx" name="fileUpload"
                 (change)="onFileSelect($event)">
        </div>
        <div class="col-md-8 pl-0-lg">
          <div class="files-preview">
            <p *ngIf="!uploadedFiles.length" class="preview-note">No files selected</p>
            <div class="row item m-0" *ngFor="let file of uploadedFiles ; let i = index">
              <div class="col-2 p-0 d-flex justify-content-center align-self-center">
                <i class="fa fa-times close-icon justify-content-center" (click)="removeFileFromUploaded(i)"></i>
              </div>
              <div class="col-10 pr-0 d-flex justify-content-start align-self-center">
                <span class="file-name justify-content-center">{{file.filename}}</span>
              </div>
            </div>
          </div>
          <p *ngIf="filesError" class="invalid-feedback d-block">{{filesError}}</p>
        </div>
      </div>
      <!--File upload-->

      <div class="row">
        <div class="col">
          <h6 class="text-center">OR</h6>
        </div>
      </div>


      <div class="row mb-2">

        <div class="col-12">
          <label for="linkedInUrl">linkedIn Profile</label>
        </div>

        <div class="col-1 justify-content-center align-self-center">
          <i class="fa fa-linkedin-square linkedin"></i>
        </div>

        <div class="col">
          <input id="linkedInUrl" type="text" formControlName="linkedInUrl" class="form-control"
                 [ngClass]="{'is-invalid': applyJobForm.get('linkedInUrl').errors && submitted}"/>
          <control-validate-error class="control-validate-error" [control]="applyJobForm.get('linkedInUrl')"
                                  [isSubmitted]="submitted"></control-validate-error>
        </div>

      </div>
      <!--LinkedIN-->

      <div class="row mb-5">

        <div class="col-12">
          <label for="portfolioUrl">Portfolio url</label>
        </div>

        <div class="col-1 justify-content-center align-self-center">
          <i class="fa fa-address-card portfolio"></i>
        </div>

        <div class="col">
          <input id="portfolioUrl" type="text" formControlName="portfolioUrl" class="form-control"
                 [ngClass]="{'is-invalid': applyJobForm.get('portfolioUrl').errors && submitted}"/>
          <control-validate-error class="control-validate-error" [control]="applyJobForm.get('portfolioUrl')"
                                  [isSubmitted]="submitted"></control-validate-error>
        </div>
      </div>
      <!--Portfolio Url-->


      <div class="form-group row">


        <div class="col">
          <button class="send-btn">
            <i *ngIf="showLoader && !applyJobForm.invalid" class="fa fa-spin fa-spinner loader-spin"></i>
            <img class="img-fluid d-block mx-auto btn-actions green" [ngClass]="{'disabled': applyJobForm.invalid}"
                 src="../../../assets/images/buttons/send.png" alt="send">
          </button>
        </div>


      </div>
      <!--Send-->

    </form>
  </section>

</div>
