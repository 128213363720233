<div id="{{modalId}}">
  <header class="modal-header">
    <h1 *ngIf="modalData.title">{{modalData.title}}</h1>
  </header>
  <section class="modal-body">
    <p *ngIf="modalData.message">{{modalData.message}}</p>
  </section>
  <footer class="modal-footer">
    <button  class="close-modal" mat-raised-button color="primary" (click)="closeModal()">Go back</button>
  </footer>
</div>
