import {Component, Input} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {ValidationService} from 'src/app/services/validation/validation.service';

@Component({
  selector: 'control-validate-error',
  template: `<div *ngIf="errorMessage !== null" class="invalid-feedback d-block">{{errorMessage}}</div>`
})

export class ControlValidateMsgComponent {
  @Input() control: FormControl;
  @Input() isSubmitted: boolean;

  constructor() {
  }

  get errorMessage() {
    for (let propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched || this.control.errors.hasOwnProperty(propertyName) && this.isSubmitted) {
        return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }
    return null;
  }
}
