import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ValidationService} from 'src/app/services/validation/validation.service';
import {MustMatch} from 'src/app/_helpers/must-match.validator';

import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogModalComponent} from 'src/app/components/utils/dialog-modal/dialog-modal.component';

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss']
})

export class ContactUsFormComponent implements OnInit {
  @Input() userObj: object;

  serverUrl = 'https://api.babawildslots.com/social/contactSupport';
  contactUsForm: FormGroup;
  submitted = false;
  uploadedFiles: Array<{}> = [];
  filesError: string;
  isSuccess: boolean;

  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient, public matDialog: MatDialog) {
    this.filesError = null;
    this.createForm();
  }

  createForm() {
    this.contactUsForm = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100)
        ]
      ],
      lastName: ['',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100)
        ]],
      email: ['', [Validators.required, ValidationService.emailValidator]],
      emailConfirmation: ['', [Validators.required, ValidationService.emailValidator]],
      issue: ['', Validators.required],
      message: ['',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(1000)
        ]
      ],
      uploadedFiles: ['']
    }, {
      validator: MustMatch('email', 'emailConfirmation')
    });
  }

  checkFileValidity(fileType, fileSize) {
    var filesCount = this.uploadedFiles.length;
    var isValid = true;
    if (filesCount >= 1) {
      this.filesError = 'Max files limited to 1';
      isValid = false;
    }
    if (fileSize >= 1000000) {
      this.filesError = 'Unable to upload files larger than 1 MB';
      isValid = false;
    }
    if (isValid) {
      this.filesError = null;
    } else {
      setTimeout(() => {
        this.filesError = null;
      }, 2500);
    }
    return isValid;
  }

  removeFileFromUploaded(index) {
    this.uploadedFiles.splice(index, 1);
  }

  updateUploadedFiles(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // file.path = reader.result as string;
      // this.contactUsForm.patchValue({
      //   fileSource: reader.result
      // });
      // this.uploadedFiles.push({path: file.path, name: file.name});
      const binaryString = reader.result as string;
      file.content = window.btoa(binaryString);
      const fileObj = this.setFileObj(file, binaryString);
      this.uploadedFiles.push(fileObj);
    };
  }

  setFileObj(file, binaryString) {
    const fileObj = {
      name:  file.name,
      path: binaryString
    };
    return fileObj;
  }

  //form messages
  openSuccessModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'formSuccessModal';
    dialogConfig.data = {
      type: 'success',
      title: 'Form successfully submitted!',
      message: 'Thank you for contacting us. we will get back to you as soon as possible'
    };
    this.matDialog.open(DialogModalComponent, dialogConfig);
  }

  openFailureModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'formFailureModal';
    dialogConfig.data = {
      type: 'failure',
      title: 'Form failed to submit.',
      message: 'Something went wrong please try again later.'
    };
    this.matDialog.open(DialogModalComponent, dialogConfig);
  }


  //events
  ngOnInit(): void {
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      for (let file of event.target.files) {
        if (this.checkFileValidity(file.type, file.size)) {
          this.updateUploadedFiles(file);
        } else {
          return;
        }
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactUsForm.invalid) {
      // this.submitted = false;
      return;
    }

    this.isSuccess = false;

    //adding uploaded files to formData
    const formData = new FormData();
    formData.append('file', this.contactUsForm.get('uploadedFiles').value);
    this.contactUsForm.get('uploadedFiles').setValue(this.uploadedFiles);


    //send an http post
    this.httpClient.post<any>(this.serverUrl, this.contactUsForm.value).subscribe(
      (res) => {
        this.openSuccessModal();
        this.submitted = false;
        this.isSuccess = true;
        this.contactUsForm.reset();
      },
      (err) => {
        if (err && err.status === 200) {
          return this.openSuccessModal();
        }
        console.warn(err);
        this.submitted = false;
        this.isSuccess = false;

        this.openFailureModal();
      }
    );

  }

}
