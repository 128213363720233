import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})

export class HomePageComponent implements OnInit {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  // slides = [
  //   {img: "../../../assets/images/gallery/slide-1.jpg"},
  //   {img: "../../../assets/images/gallery/slide-2.jpg"},
  //   {img: "../../../assets/images/gallery/slide-3.jpg"},
  //   {img: "../../../assets/images/gallery/slide-4.jpg"},
  //   {img: "../../../assets/images/gallery/slide-5.jpg"},
  //   {img: "../../../assets/images/gallery/slide-6.jpg"},
  //   {img: "../../../assets/images/gallery/slide-7.jpg"},
  //   {img: "../../../assets/images/gallery/slide-8.jpg"},
  //   // {img: "../../../assets/images/gallery/slide-9.jpg"},
  //   {img: "../../../assets/images/gallery/slide-10.jpg"},
  //   {img: "../../../assets/images/gallery/slide-11.jpg"},
  //   {img: "../../../assets/images/gallery/slide-12.jpg"},
  //   {img: "../../../assets/images/gallery/slide-13.jpg"},
  //   {img: "../../../assets/images/gallery/slide-14.jpg"},
  //   {img: "../../../assets/images/gallery/slide-15.jpg"}
  // ];
  // slideConfig = {
  //   "slidesToShow": 3,
  //   "slidesToScroll": 3,
  //   "autoplay": true,
  //   "arrows": false,
  //   "autoplaySpeed": 3000,
  //   "responsive": [
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         "slidesToShow": 1,
  //         "slidesToScroll": 1,
  //       }
  //     }
  //   ]
  // };

  constructor() {
  }


  // playVid() {
  //   this.videoPlayer.nativeElement.muted = true;
  //   this.videoPlayer.nativeElement.play();
  //   this.videoPlayer.nativeElement.pause();
  //   this.videoPlayer.nativeElement.play();
  // }


  ngOnInit(): void {
    setTimeout(() => {
      AOS.init();
      // this.playVid();
    }, 1000);

  }


}
