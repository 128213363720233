import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss']
})

export class DialogModalComponent implements OnInit {
  modalId: any;
  modalData: {
    title:'',
    message:''
  };

  constructor(public dialogRef: MatDialogRef<DialogModalComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.modalId = this.dialogRef.id;
    this.modalData = data;
  }

  ngOnInit() {

  }

  closeModal() {
    this.dialogRef.close();
  }

}
