<app-header></app-header>


<div class="container general-info-page">
  <h2><span>Baba Entertainment Ltd.’s </span>Terms of Service</h2>
  <div class="text-left">
    <article>
      <h3>In Short</h3>
      <p>The following key points of these Terms of Use are brought for your convenience only. They do not
        substitute the full Terms.
      </p>
      <ol>
        <li><b>Baba Entertainment.</b> The Service is owned and operated by Baba Entertainment Ltd.</li>
        <li><b>The Service. </b> Baba Entertainment is a social gaming platform, offer players like you to play
          live games such as poker, roulette and slot machines with other Players within the Service.
        </li>
        <li><b>Age restriction.</b> You must be 18 years of age or older in order to use this Service.</li>
        <li><b>Privacy.</b> We respect your privacy as further explained in our
          <a routerLink="/privacy-policy">Privacy Policy</a>
        </li>
        <li><b>Fees.</b> The Service is free of charge, but certain features within the Service may be subject to
          the fees posted next to them on the Service.
        </li>
        <li><b>Intellectual property.</b> All legal rights in the Service, including all intellectual property
          rights, are Baba Entertainment’s. Obviously, we don’t own the user content.
        </li>
        <li><b>Content of third parties.</b> We do not claim ownership over content that originates from players
          or third parties.
        </li>
        <li><b>Commercial content.</b>We may display advertisements in the Service, but we do not endorse or
          recommend advertised content.
        </li>
        <li><b>Disclaimer of warranty.</b> The Service is provided for use as is. We disclaim all warranties and
          representations with respect to the Service.
        </li>
        <li><b>Limitation of liability.</b> To the maximum extent permitted by the applicable law, we – and anyone
          acting on our behalf – will not be liable for any damage or loss, arising from the use or inability to
          use the Service.
        </li>
        <li><b>Law & jurisdiction.</b> Use of the Service is governed by the laws of the State of Israel and
          subject to the exclusive jurisdiction of the competent courts in the District of Tel-Aviv-Jaffa.
        </li>
      </ol>

    </article>

    <article>
      <h3>More detailed</h3>
      <p>Welcome to <b>Baba Entertainment</b>, a social gaming platform (the <b>"Service"</b>). The Service is
        owned and operated by <b>Baba Entertainment Ltd. ("Baba Entertainment", "we", "us" and "our").</b> It is
        available through our website <a href="www.baba-entertainment.com">www.baba-entertainment.com</a> (the <b>"Website"</b>),
        various social networks websites and our designated mobile and Smart TV application (the <b>"App"</b>).

      </p>
      <p>Please read the following Terms of Use (the <b>"Terms"</b>) carefully. By installing, signing up to,
        accessing, using or sending Content on the Service, you agree to these Terms. If you do not agree to these
        Terms, you may not access or use the Service.
      </p>
      <p>Use of the Service may be subject to additional terms and conditions that govern the use of Apple
        devices, application marketplaces or other services that interact with the Service. You alone are
        responsible for complying with such additional terms and conditions.
      </p>

    </article>
    <br/>

    <article>
      <h3>ACCESS TO THE SERVICE</h3>
      <p>Use of the Service is intended and permitted for persons 18 years of age or older. By using, accessing or
        registering with the Service you hereby declare that you are 18 years of age or older. <b>IF YOU ARE UNDER
          THE AGE OF 18 YOU MAY NOT USE OR ACCESS THE SERVICE IN ANY WAY.</b></p>

      <p>We reserve the right to terminate an account, if we find that your age is less than the minimum age. We
        may request additional information to confirm your age at any time. Any participant found to be under the
        minimum age will have their account terminated and their Games’ records will be void.
      </p>
      <p>PLEASE NOTE THAT IT IS YOUR SOLE RESPONSIBILITY TO DETERMINE AND VERIFY THAT YOU ARE LEGALLY PERMITTED
        UNDER THE LAWS OF YOUR RESIDENCE TO USE THE SERVICE. </p>
      <p>BY USING OUR SERVICES YOU DECLARE THAT YOU AGREE TO ABIDE BY ALL APPLICABLE LOCAL AND INTERNATIONAL LAWS,
        REGULATIONS AND RULES, INCLUDING ANY AND ALL LAWS, RULES AND REGULATION PERTAINING TO GAMBLING, TABLE
        GAMES AND LOTTERIES.
      </p>
    </article>
    <br/>

    <article>
      <h3>ABOUT US</h3>
      <p><b>The Games.</b> Baba Entertainment is a social gaming platform, offer players like you
        (<b>"Players"</b>) to play live games such as poker, roulette and slot machines with other Players within
        the Service (collectively, the <b>"Games"</b>). The Games are held in online gaming tables and are
        broadcasted to you using Baba Entertainment’s unique technology (the <b>"Gaming Tables"</b>). Baba
        Entertainment also offers you live chat platform within the Gaming Tables that allows you to communicate
        with other Players (<b>"Chat"</b>).
      </p>
      <p>
        <b>Virtual Coins. </b> Games may include virtual in-game currency including coins, cash or credits (<b>"Virtual
        coins"</b>). You may earn Virtual Coins by (i) winning Games; (ii) receiving gifts from your friends in
        Facebook or other social network; (iii) inviting friends to join the Service; or by (iv) purchasing
        Virtual Coins or scratch cards through our online store.
      </p>
      <p><b>NO PURCHASE IS NECESSARY TO ENTER OR WIN A GAME. A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE
        YOUR CHANCES OF WINNING. VIRTUAL COINS CAN BE USED ONLY WITHIN THE SERVICE AND MAY NEVER BE REDEEMED FOR
        MONEY, GOODS OR OTHER ITEMS WITH MONETARY VALUE. </b></p>

      <p><b>Invitation. </b> You can invite friends and contacts to join the Service through email, Whatsapp and
        social networks websites. You assume full responsibility for such invitations. </p>

      <p><b>Broadcasting Gaming Tables. </b> Baba Entertainment’s unique streaming technology enables Players to
        play certain Games through a designated smart TV App that display the Gaming Table and the game progress
        on Smart TV, whereas each invited Player may participate in the Gaming Table using his mobile or tablet,
        as game controller. </p>

      <p><b>Chats.</b> s a Player you can communicate with other Players using the Chat platform available through
        our Service. You can chat with Players participating in a certain Gaming Table, either privately or in a
        group chat. Likewise, other Players participating in a certain Gaming Table may communicate with you.<br>
        The Chat available through the Service is operated and controlled by Facebook, all information and
        functionalities associated with a Chat, including its members, permissions, properties, settings and
        Content, originate from Players or Facebook, but not us. We do not control nor assume any responsibility
        for the Chat system, its availability and the Content communicated through it.
        <br>
        Please note that your use of the Chat platform, including the messages you send and its content, is
        governed by Facebook’s <a href="https://www.facebook.com/terms">terms of use</a> and
        <a routerLink="/privacy-policy">Privacy Policy</a>
        , not these Terms.
      </p>

    </article>
    <br/>

    <article>
      <h3>REGISTRATION TERMS </h3>
      <p>
        <b>Information you provide.</b> The Service is available only to registered users. Use of the Service
        requires registration of an account through the Service. When you register to the Service, we will ask you
        to provide us the details we describe in our <a href="http://baba-entertainment.com/privacy-policy.html">privacy
        policy</a> (<b>"Registration Information"</b>). Once registered, you may access the Service using your
        account username and password. Make sure that you change your password periodically. You must maintain the
        confidentiality of your account login details.
      </p>
      <p><b>False information. </b>If we believe that the Registration Information you provide is false, deceptive
        or offensive, or if we believe that you violated these Terms, we reserve the right to suspend or terminate
        your user account or your access to the Service.
      </p>
      <p><b>Additional Information. </b>We reserve the right to request additional information to verify your
        identity or age, during the registration process, throughout your use of the Service or when you submit
        support tickets related to your Service account.
      </p>
    </article>
    <br/>

    <article>
      <h3>YOUR PRIVACY </h3>
      <p>We respect your privacy. Our <a href="http://baba-entertainment.com/privacy-policy.html">Privacy
        Policy</a>, which is combines into these Terms, explains our privacy practices. We encourage you to read
        it carefully.
    </article>
    <br/>

    <article>
      <h3>Fee-based Services</h3>
      <p><b>Fee based Services.</b> Use of the Service is free of charge. Except for Virtual Coins that you may
        purchase within the Service, subject to fees at the rates and schemes determined and posted next to them
        on the Service (<b>"Fee-Based Services"</b>). Fee-Based Services can only be paid through PayPal, Facebook
        or one of the other payment methods we establish from time to time. Without derogating from the above, we
        may, at any time and in our sole discretion, change a free-of-charge feature to a fee-based feature. </p>
      <p><b>Refund or Cancellation.</b>Unless otherwise mandated by law, all your payment obligations are
        non-cancelable and all amounts paid in connection therewith are non-refundable by Entertainment. You are
        solely responsible for paying all applicable fees for the Products that you purchased, whether or not you
        actually attend, enjoyed or otherwise benefited from them.
      </p>
      <p><b>Failing to Pay.</b> Failing to settle your payments for Fee-Based Services will prevent you from
        continuing to use the Service, without regard to any other remedies available to us under applicable law.
      </p>
    </article>
    <br/>


    <article>
      <h3>Commercial Content</h3>
      <p><b>Ads.</b> We may incorporate advertisements or commercial information in the Service. We do not control
        the ads and cannot guarantee that they are reliable or accurate. Including them in the Service does not
        mean that we recommend or endorse the goods or services that they offer. If you choose to purchase them,
        you do it on your own accord and we bear no responsibility for your decision or its consequences. </p>
    </article>
    <br/>

    <article>
      <h3>CONTENT </h3>
      <p><b>Links.</b> The Service may include links to other online resources that third parties provide. We do
        not operate or monitor these online resources or their content. We assume no responsibility or liability
        for such third party content or their availability and we bear no responsibility for your decision to open
        such links and the consequences of doing so. </p>
    </article>
    <br/>

    <article>
      <h3>USE OF THE SERVICE </h3>
      <p><b>Prohibited use. </b>When using the Service, you must refrain from –

        You are solely responsible for the content you make available through the service and for the consequences
        associated with doing so.
      </p>
      <ul>
        <li>Using the service if you are under 18 years of age or under the legal age of majority in your
          jurisdiction;
        </li>
        <li>Breaching these Terms or any other applicable rules and instructions that we may convey with respect
          to the Service;
        </li>
        <li>Engaging in any activity that constitutes or encourages conduct that would constitute a criminal
          offense, give rise to civil liability or otherwise violate any applicable law, including laws governing
          privacy, defamation, spam and copyright;
        </li>
        <li>Interfering with, burdening or disrupting the functionality of the Service;</li>
        <li>Breaching the security of the Service or publicly identifying any security vulnerabilities in it;</li>
        <li>Circumventing or manipulating the operation or functionality of the Service, or attempting to enable
          features or functionalities that are otherwise disabled, inaccessible or undocumented in the Service;
        </li>
        <li>Sending automated or machine generated queries;</li>
        <li>Using robots, crawlers and similar applications to collect and compile content from the Service or
          send data to the Service including for the purposes of competing with the Service, or in such ways that
          may impair or disrupt the Service's functionality;
        </li>
        <li>Displaying or embedding content from the Service, including by any software, feature, gadget or
          communication protocol, which alters the content or its design;
        </li>
        <li>Impersonating any person or entity, or making any false statement pertaining to your identity or
          affiliation with any person or entity;
        </li>
        <li>Collecting, harvesting, obtaining or processing personal information regarding the Service’s users,
          without their prior explicit consent;
        </li>
        <li>Abusing, harassing, threatening or intimidating other users of the Service; Linking to the Service
          from web pages or applications that contain pornographic content or content that encourages racism or
          wrongful discrimination;
        </li>
        <li>Sending, uploading to or making available on the Service Content which may be considered as -
          <ul>
            <li>Infringing or violating intellectual property rights of other parties, including copyrights,
              patents, trade secrets and trademarks;
            </li>
            <li>Identifying minors, their personal details or their address and ways to contact them;</li>
            <li>Software viruses, Trojan Horses, worms, vandals, spyware, ransomware and any other malicious
              applications;
            </li>
            <li>Constituting a violation of a person's right to privacy or right of publicity;</li>
            <li>Threatening, abusive, harassing, defamatory, libellous, vulgar, violent, obscene or racially,
              ethnically or otherwise objectionable;
            </li>
            <li>Unsolicited commercial communications ('spam'), chain letters, or pyramid schemes.</li>
            <li>Otherwise prohibited by any applicable law</li>
          </ul>
        </li>

      </ul>
      <p>
        <b>We encourage respectful behaviour. </b>If you find any content on the Service that violates these
        Terms, please let us know by contacting us at: <a href="mailto:info@babaent.net">info@babaent.net</a>. We
        will review every request and take action as necessary.
      </p>
    </article>
    <br/>

    <article>
      <h3>INTELLECTUAL PROPERTY </h3>
      <p><b>Our intellectual property. </b> All rights, title and interest in and to the Service, including
        without limitation, patents, copyrights, trademarks, trade names, Service marks, trade secrets and other
        intellectual property rights and any goodwill associated therewith, but excluding any Content that
        originates from Users, are the exclusive property of Baba Entertainment and its licensors. This including
        the Service’s design, graphics, computer code, “look and feel” and the Company’s domain name. </p>

      <p><b>Restrictions</b> You may not copy, distribute, display or perform publicly, make available to the
        public or communicate to the public, sublicense, decompile, disassemble, reduce to human readable form,
        execute publicly, make available to the public, adapt, make commercial use of, process, compile,
        translate, sell, lend, rent, reverse engineer, combine with other software, modify or create derivative
        works of the Service or the Service or any part thereof, in any way or by any means. <br/> You may not use
        any name, mark, logo or domain name that is confusingly similar to our marks, logos and Internet domain
        names. You must refrain from any action or omission that may dilute, or damage our goodwill. </p>

    </article>
    <br/>

    <article>
      <h3>TERMINATION </h3>

      <p><b></b></p>
    </article>
    <br/>

    <article>
      <p><b>Terminating Your Account. </b> You may, at any time, request to terminate your account by contacting
        us at <a href="mailto:info@babaent.net">info@babaent.net</a>. Please note that merely uninstalling the App
        won't delete your account and its details, including all the information associated with it, which would
        still remain on our systems. </p>
      <p>We may temporarily or permanently limit, block your access to or terminate your user account, if we
        determine that you breached these Terms. </p>
      <p>Upon termination of these Terms or your account, for any reason, your right to use the Service is
        terminated and you must immediately cease using the Service; and we will not be liable to you for
        termination of access to the Service. </p>

      <p><b>Operation of the Service. </b>We may at any time discontinue or terminate the operation of the App or
        the Service, or any part thereof, temporarily or permanently, for all users, or for certain users, without
        any liability to you. </p>
    </article>
    <br/>


    <article>
      <h3>Changes </h3>
      <p><b>Changing the Service. </b>We may, at any time and without prior notice change the layout, design,
        scope, features or availability of the App and the Service. </p>
      <p><b>Changing these Terms. </b> We may revise these Terms, in whole or in part, at any time by putting you
        on notice of the amended Terms. Your continued use of the Service after the effective date of the amended
        Terms constitutes your consent to the amended Terms. </p>

    </article>

    <article>
      <h3>DISCLAIMER OF WARRANTY </h3>
      <p>THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS. WE AND OUR OFFICERS,
        DIRECTORS, SHAREHOLDERS, EMPLOYEES, SUB-CONTRACTORS, AGENTS AND AFFILIATES (THE “STAFF”) DISCLAIM ALL
        WARRANTIES AND REPRESENTATIONS, EITHER EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICE, ITS CONTENT,
        INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE,
        QUALITY, NON-INFRINGEMENT, TITLE, COMPATIBILITY, PERFORMANCE, SECURITY, ACCURACY, ENHANCED GOODWILL OR
        EXPECTED BENEFITS. </p>
      <p>WE DO NOT WARRANT THAT (1) THE SERVICE WILL BE UNINTERRUPTED, ERROR FREE OR WILL MEET YOUR REQUIREMENTS
        OR EXPECTATIONS IN ANY WAY; (2) THE SERVICE WILL ALWAYS BE AVAILABLE OR FREE FROM MALWARES, COMPUTER
        VIRUSES OR OTHER HARMFUL COMPONENTS; (3) THE QUALITY OF THE SERVICE, THE GAMES, CHATS OR OTHER CONTENT
        AVAILABLE THROUGH OR IN THE SERVICE, WILL MEET YOUR EXPECTATIONS; (4) THE RESULTS OF THE USE OF THE
        SERVICE WILL BE SATISFACTORY AND WILL FIT YOUR EXPECTATIONS OR REQUIREMENTS. </p>
      <p>NO REPRESENTATION OR OTHER AFFIRMATION OF FACT, INCLUDING STATEMENTS REGARDING CAPACITY, SUITABILITY FOR
        USE OR PERFORMANCE OF THE SERVICE, OR THE CONTENT PRESENTED ON OR THROUGH THE SERVICE, WHETHER OR NOT MADE
        BY ANY OF OUR STAFF, WHICH IS NOT EXPRESSLY CONTAINED IN THESE TERMS, SHALL BE DEEMED TO BE A WARRANTY BY
        THE INVOLVED PERSONS FOR ANY PURPOSE, OR GIVE RISE TO ANY LIABILITY OF OUR STAFF WHATSOEVER. </p>
      <p>YOU AGREE AND ACKNOWLEDGE THAT THE USE OF THE SERVICE IS ENTIRELY, OR AT THE MAXIMUM PERMITTED BY THE
        APPLICABLE LAW, AT YOUR OWN RISK. </p>
    </article>

    <article>
      <h3>LIMITATION OF LIABILITY </h3>
      <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR STAFF, SHALL NOT BE LIABLE, FOR ANY
        INDIRECT, SPECIAL, PUNITIVE, EXEMPLARY, STATUTORY, INCIDENTAL OR CONSEQUENTIAL DAMAGE INCLUDING BODILY
        INJURY OR OTHER DAMAGE CAUSED BY USING THE SERVICE, OR ANY SIMILAR DAMAGE OR LOSS (INCLUDING LOSS OF
        PROFIT AND LOSS OF DATA), COSTS, EXPENSES AND PAYMENTS, EITHER IN TORT, CONTRACT, OR IN ANY OTHER FORM OR
        THEORY OF LIABILITY, ARISING FROM, OR IN CONNECTION WITH THE CONTENT, THE USE OF, OR THE INABILITY TO USE
        THE SERVICE OR ITS FEATURES, OR FROM ANY FAILURE, ERROR, OR BREAKDOWN IN THE FUNCTION OF THE SERVICE, OR
        FROM ANY FAULT, OR ERROR MADE BY OUR STAFF, OR FROM YOUR RELIANCE ON CONTENT AVAILABLE ON OR THROUGH THE
        SERVICE, OR FROM ANY COMMUNICATION THROUGH THE SERVICE, OR WITH OTHER USERS ON THE SERVICE, OR FROM ANY
        DENIAL OR CANCELATION OF YOUR USER ACCOUNT, OR FROM RETENTION, DELETION, DISCLOSURE AND ANY OTHER USE OR
        LOSS OF YOUR CONTENT ON THE SERVICE</p>
      <p>IN ANY EVENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR TOTAL, MAXIMUM AND AGGREGATE
        LIABILITY TO YOU FOR DIRECT DAMAGES SHALL BE LIMITED TO HALF THE FEES YOU PAID US (IF ANY) IN THE TWELVE
        MONTHS PRECEDING THE EVENT PURPORTEDLY GIVING RISE TO THE CLAIM. </p>
      <p>NOTWITHSTANDING ANYTHING HEREIN TO THE CONTRARY, WE WILL BE FULLY RELEASED FROM OUR OBLIGATIONS AND
        LIABILITY TO YOU IF YOU HAVE BREACHED THESE TERMS, ANY OTHER TERMS, RULES OR REGULATIONS APPLICABLE TO THE
        SERVICE, OR IF THROUGH YOUR USE OF THE SERVICE, YOU INFRINGED OR VIOLATED ANY OTHER PERSON’S RIGHTS. </p>
    </article>

    <article>
      <h3>Indemnification </h3>
      <p>To the maximum extent permitted by law, you will indemnify and hold harmless at your own expense, us, our
        Staff and anyone acting on our behalf, from and against any damages, costs and expenses, resulting from
        any claim, allegation or demand, connected with your use of the Service, your breach of these Terms or
        infringement of any other person’s rights. </p>
    </article>

    <article>
      <h3>Application Marketplace </h3>
      <p>Your use of the Service may be subject to additional third party terms and conditions that govern that
        application marketplace from which you downloaded the App, such as iTunes, Google Play or Amazon App-store
        for Android. Such third parties are not responsible for providing maintenance and support services with
        respect to the App. </p>
      <p>The following terms apply if you downloaded an App from Apple's App Store. You and us agree and
        acknowledge as follows: </p>
      <p>These Terms are concluded between yourself and us, and not with Apple Inc. ("Apple"). Apple is not
        responsible for the App. In the event of a conflict between these Terms and the App Store Terms of Service
        then the App Store Terms of Service will prevail, solely with respect to the conflicting provisions. </p>
      <p>The license granted to you for the App is limited to a non-transferrable license to use the App on any
        iOS Products that you own or control, and as permitted by the Usage Rules set forth in the App Store Terms
        of Service, except that such App may be accessed, acquired, and used by other accounts associated with the
        purchaser via Family Sharing. </p>
      <p>In the event of a failure to conform to any applicable warranty (if any warranty is applicable), you may
        notify Apple, and Apple will refund the purchase price for the App to you (if you paid any). Apple has no
        other warranty obligation whatsoever with respect to the App, and any other claims, losses, liabilities,
        damages, costs or expenses attributable to any failure to conform to any warranty, will not be at Apple’s
        responsibility. </p>
      <p>Apple is not responsible for addressing any claims by you or any third party relating to the App or your
        possession and/or use of the App, including (i) product liability claims, (ii) any claim that the App
        fails to conform to any applicable legal or regulatory requirement, and (iii) claims arising under
        consumer protection or similar legislation. </p>
      <p>In the event of any third party claim that the App or your possession and use of the App infringes that
        third party’s IP Rights, Apple will not be responsible for the investigation, defense, settlement and
        discharge of any such infringement claim. </p>
      <p>You must comply with applicable third party terms of agreement when using the App (e.g. you must not be
        in violation of your wireless data Services agreement when you use the App). </p>
      <p>Apple and Apple’s subsidiaries are third party beneficiaries of these Terms. Upon Your acceptance of
        these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these
        Terms against you as a third party beneficiary thereof. You represent and warrant that (i) you are not
        located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S.
        Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of
        prohibited or restricted parties. </p>

    </article>

    <article>
      <h3>Governing Law, jurisdiction </h3>
      <p>Regardless of your place of residence or where you access or use the Service from, these Terms and your
        use of the Service will be governed by and construed solely in accordance with the laws of the State of
        Israel. </p>
      <p>The competent courts in the District of Tel-Aviv-Jaffa in Israel will have exclusive and sole
        jurisdiction over any dispute, claim or controversy relating to the Service and the Service or with
        respect to any matter relating to these Terms. You hereby expressly consent to personal jurisdiction in
        Israel and expressly waive any right to object to such personal jurisdiction or the non-convenience of
        such forum. </p>
      <p>Notwithstanding the foregoing, we may lodge a claim against you pursuant to the indemnity clause above in
        any court adjudicating a third party claim against us. </p>

    </article>


    <article>
      <h3>General </h3>
      <p><b>Assignment. </b>You may not assign or transfer your rights and obligations under these Terms without
        our prior written consent. Any attempted or actual assignment by you, without our prior written consent,
        shall be null and void. </p>
      <p><b>Changes in ownership. </b> In the event of M&A, we may, upon notice to you and without obtaining your
        consent, assign and delegate these Terms, including all of our rights, performances, duties, liabilities
        and obligations contained herein, to a third party. </p>
      <p><b>Severability. </b>If any provision of these Terms is held to be illegal, invalid, or unenforceable by
        a competent court, then the provision shall be performed and enforced to the maximum extent permitted by
        law to reflect as closely as possible, the original intention of that provision, and the remaining
        provisions of these Terms shall continue to remain in full force and effect. </p>
      <p><b>Interpretation. </b>The section headings in these Terms are included for convenience only and shall
        take no part in the interpretation or construing of these Terms. Whenever used in these Terms, the term
        "Including", whether capitalized or not, means without limitation to the preceding phrase. All examples
        and e.g. notations are illustrative, not exhaustive. </p>
      <p><b>Entire agreement. </b>These Terms constitute the entire agreement between you and us concerning the
        subject matter herein, and supersede all prior and contemporaneous negotiations and oral representations,
        agreements and statements. </p>
      <p><b>Waivers. </b>No waiver, concession, extension, representation, alteration, addition or derogation from
        these Terms by us, or pursuant to these Terms, will be effective unless consented to explicitly and
        executed in writing by our authorized representative. Failure on our part to demand performance of any
        provision in these Terms shall not constitute a waiver of any of our rights under these Terms. </p>
      <p><b>Relationship. </b>These Terms do not create any agency, partnership, employment or fiduciary
        relationship between you and us. </p>
    </article>

    <article>
      <h3>CONTACT US </h3>
      <p>At any time, you may contact us with any question, request, comment or complaint that you may have with
        respect to the Service or these Terms, at: <a href="mailto:info@babaent.net">info@babaent.net</a>, or
        through our online contact form. </p>
    </article>

    <article>

      <p>Effective Date: January 10, 2017.</p>
    </article>

  </div>
</div>


<app-footer></app-footer>
